<template>
  <div class="home">
    <Banner></Banner>
      <div class="container-fluid logo d-flex justify-content-center">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div>
              <h3 class="font-weight-bold" style="padding: 20px;">Move Vertically and Laterally</h3>
            </div>
             <div>
              <p>
                All hiring managers on Generalists value demonstrated meta-learning over role tenure.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <UseCases></UseCases>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import UseCases from "@/components/CandidateUseCases.vue";

export default {
  name: "home",
  components: {
    Banner,
    UseCases
  }
};
</script>

<style scoped>
.logo {
  display: flex;
  align-items: center;
  height: 300px;
  color: #fff;
  background-color: #31314F;
  background-image: url("../../src/assets/logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
</style>
