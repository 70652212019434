import Firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

// firebase initial
const config = {
  apiKey: "AIzaSyDQlsasim_nQyUKYTpCQv__WEmkHgujDAU",
  authDomain: "generalistsco.firebaseapp.com",
  databaseURL: "https://generalistsco.firebaseio.com",
  projectId: "generalistsco",
  storageBucket: "generalistsco.appspot.com",
  messagingSenderId: "832067564071"
};

let App = Firebase.initializeApp(config);

export let FirebaseApp = App;
export let FirebaseDb = App.database();
export let FirebaseAuth = App.auth();
