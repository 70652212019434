<template>
  <div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <h1 class="font-weight-bold" style="margin-top: 25px; margin-bottom: 30px;">What Thought Leaders Are Saying</h1>
        <div style="text-align: justify; margin-bottom: 70px;">
          <p>
            <router-link to="/blog/the-most-predictive-trait-of-the-best-employees">According to research and industry leaders</router-link>, the most predictive trait of successful hires is
             meta-learning—the drive and ability to learn new skills. This meta-trait consists of traits such as curiosity,
             persistence, initiative, and leadership.
          </p>
          <div style="text-align: center;">
            <img class="img-fluid ramp" src="../../src/assets/rampchart.png" style="margin-top: -20px; max-height: 400px;" alt="line chart contrasting the performance of modal role experts against meta-learners">
            <img class="img-fluid" src="../../src/assets/vennhorizontal.png" alt="venn diagram comparison contrasting conventional hiring with behavioral hiring">
          </div>
          <p>
            Most hiring optimizes for role tenure and prioritizes short-term outcomes, but meta-learning beats tenure almost every time and delivers greater long-term value. A track record of rigorous learning throughout
            one’s career, whether as a specializing generalist or generalizing specialist, most clearly demonstrates this trait.
          </p>
          <p>
            Together, we call them <span style="color: #EF5556; font-weight: bold;">Generalists - masters of the #1 predictive trait of successful hires.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Generalists - Why",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Specializing generalists and generalizing specialists are both meta-learners, masters of the #1 predictive trait of successful hires."
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Specializing generalists and generalizing specialists are both meta-learners, masters of the #1 predictive trait of successful hires."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "Generalists - Why"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://generalists.co/why"
      }
    ]
  }
};
</script>

<style>
@media (max-width: 768px) {
  .ramp {
    max-width: 300px;
  }
}
</style>