import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import Home from "./views/Home.vue";
import Why from "./views/Why.vue";
import Blog from "./views/Blog.vue";
import BlogPost from "./views/BlogPost.vue";
import Hiring from "./views/Hiring.vue";
import About from "./views/About.vue";
import SignUp from "./views/SignUp.vue";
import ThankYou from "./views/ThankYou.vue";
// import LogIn from "./views/LogIn.vue";
// import Dashboard from "./views/Dashboard.vue";
// import AdminDashboard from "./views/AdminDashboard.vue";

import store from "./store";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Home,
      meta: { fullwidth: true }
    },
    {
      path: "/why",
      component: Why
    },
    {
      path: "/hiring",
      component: Hiring,
      meta: { fullwidth: true }
    },
    {
      path: "/about",
      component: About
    },
    {
      path: "/blog",
      component: Blog
    },
    {
      path: "/blog/:slug",
      component: BlogPost
    },
    {
      path: "/signup",
      component: SignUp,
      meta: { fullwidth: true }
    },
    {
      path: "/thankyou",
      component: ThankYou,
      meta: { vCenter: true }
    }
    // {
    //   path: "/signup/:type",
    //   component: SignUp,
    //   meta: { vCenter: true }
    // },
    // {
    //   path: "/signup/:type/:referralId",
    //   component: SignUp,
    //   meta: { vCenter: true }
    // },
    // {
    //   path: "/login",
    //   component: LogIn,
    //   meta: { vCenter: true }
    // },
    // {
    //   path: "/dashboard",
    //   component: {
    //     render(c) {
    //       return c("router-view");
    //     }
    //   },
    //   children: [
    //     {
    //       path: "profile",
    //       component: Dashboard
    //     },
    //     {
    //       path: "hiring",
    //       component: Dashboard
    //     }
    //   ],
    //   beforeEnter(to, from, next) {
    //     if (to.path === "/dashboard") {
    //       store.dispatch("getUserType", true);
    //       return next();
    //     }
    //     if (store.state.userId) {
    //       next();
    //     } else {
    //       next("/login");
    //     }
    //   }
    // },
    // {
    //   path: "/admin",
    //   component: AdminDashboard,
    //   beforeEnter(to, from, next) {
    //     if (to.path === "/admin") {
    //       store.dispatch("redirectAdmin");
    //       return next();
    //     }
    //   }
    // }
  ]
});
