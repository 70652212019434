<template>
  <div class="container banner">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 main-cta" style="margin-bottom: 50px;">
        <h1 class="display-5 font-weight-bold">Let <vue-typer :text="['Curiosity', 'Passion', 'Authenticity']" :pre-type-delay='1000' /><br />Drive Your Career</h1>
        <p class="lead">Where incisive hiring managers <br /> meet world-class explorers.</p>
        <div>
          <router-link to="/signup" class="btn btn-primary btn-lg font-weight-bold" role="button" style="background-color: #31314F; border: #31314F;">For Generalists</router-link>
        </div>
        <div>
          <router-link to="/hiring" class="btn btn-primary btn-lg font-weight-bold" role="button">For Hiring Managers</router-link>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 illustration">
        <img class="img-fluid" src="../../src/assets/homeillustration.png" alt="illustration of diverse mental models and outside-the-box thinking" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueTyper } from "vue-typer";

export default {
  name: "Banner",
  components: {
    VueTyper
  }
};
</script>

<style scoped>
.btn {
  margin: 10px 0px 10px 0px;
  width: 15rem;
  border-radius: 40px;
  padding: 10px 20px 10px 20px;
}
</style>

<style>
@media (min-width: 1024px) {
  .main-cta {
    text-align: left;
  }
}

@media (max-width: 1023.98px) {
  .main-cta {
    text-align: center;
  }
}

@media (max-width: 411.98px) {
  .display-5 {
    font-size: 2em;
  }

  .banner {
    padding: 10px;
  }

  .illustration {
    margin-bottom: 60px;
  }
}

@media (min-width: 412px) {
  .banner {
    padding: 40px 35px 40px 32px;
  }

  .illustration {
    margin-bottom: 40px;
  }
}

</style>
