import Vue from "vue";
import Vuex from "vuex";
import { FirebaseDb } from "./Firebase.js";
import Router from "./router.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: null,
    userType: null
  },
  mutations: {
    login(state, uid) {
      state.userId = uid;
    },
    logoutUser(state) {
      state.userId = null;
    },
    setUserType(state, type) {
      state.userType = type;
    }
  },
  actions: {
    updateProfile({ state }, profileData) {
      FirebaseDb.ref("profiles")
        .child(state.userId)
        .set(profileData);
    },
    getUserType({ state, commit, dispatch }, redirect) {
      return FirebaseDb.ref("users")
        .child(state.userId)
        .once("value")
        .then(data => {
          commit("setUserType", data.val().userType);
          if (redirect) {
            dispatch("redirectDashboard");
          }
        });
    },
    redirectAdmin({ state, dispatch }) {
      return FirebaseDb.ref("users")
        .child(state.userId)
        .once("value")
        .then(data => {
          if (data.val().admin) {
            return;
          } else {
            dispatch("redirectDashboard");
          }
        });
    },
    redirectDashboard({ state }) {
      if (state.userType === "hiringmanager") {
        Router.push("/dashboard/hiring");
      } else {
        Router.push("/dashboard/profile");
      }
    }
  },
  getters: {
    user(state) {
      return state.userId;
    },
    isAuthenticated(state) {
      return state.userId !== null;
    }
  }
});
