<template>
  <div class="row" style="margin: 25px 0px 20px 0px;">
    <div class="col-md-12">
      <h1 class="font-weight-bold" style="font-size: 2em;">The future of work, backed by integrated research and case studies.</h1>
      <div>
        <div v-for="(post, index) in posts"
          :key="post.slug + '_' + index"
          class="card"
          style="width: 18rem;"
        >
          <img v-if="post.featured_image" :src="post.featured_image" alt="" class="card-img-top">
            <div class="card-body bg-light">
              <h5 class="card-title">{{ post.title }}</h5>
              <p class="card-text">{{ post.summary }}</p>
              <router-link :to="'/blog/' + post.slug" class="btn btn-primary">Read on</router-link>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import butter from "@/services/buttercms.js";

export default {
  name: "blog",
  data() {
    return {
      posts: []
    };
  },
  methods: {
    getPosts() {
      butter.post
        .list({
          page: 1,
          page_size: 10
        })
        .then(res => {
          this.posts = res.data.data;
        });
    }
  },
  created() {
    this.getPosts();
  },
  metaInfo: {
    title: "Generalists - Blog",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Heterodox insights on lifelong learning, hiring, and the future of work, backed by integrated research, case studies, and first principles."
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Heterodox insights on lifelong learning, hiring, and the future of work, backed by integrated research, case studies, and first principles."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "Generalists - Blog"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://generalists.co/blog"
      }
    ]
  }
};
</script>

<style>
.card {
  display: inline-block;
  margin: 20px;
}
</style>
