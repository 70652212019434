import { render, staticRenderFns } from "./CandidateUseCases.vue?vue&type=template&id=53f45eac&scoped=true&"
var script = {}
import style0 from "./CandidateUseCases.vue?vue&type=style&index=0&id=53f45eac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f45eac",
  null
  
)

component.options.__file = "CandidateUseCases.vue"
export default component.exports