import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
// import { FirebaseAuth } from "./Firebase";
// import VueClipboard from "vue-clipboard2";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faCopy } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// library.add(faCopy);
//
// Vue.component("fa-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
// Vue.use(VueClipboard);

let baseApp;

// FirebaseAuth.onAuthStateChanged(user => {
//   if (user) {
//     store.commit("login", user.uid);
//   }
/* eslint-disable no-new */
if (!baseApp) {
  /* eslint-disable no-new */
  baseApp = new Vue({
    el: "#app",
    router,
    // store,
    render: h => h(App),
    mounted() {
      document.dispatchEvent(new Event("rendered"));
    }
  });
}
// });
