<template>
  <div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div style="text-align: center;">
          <img src="../../src/assets/alt-logo.png" alt="alternate logo" height="200px;" />
        </div>
        <h1 class="font-weight-bold" style="margin-top: 25px; margin-bottom: 30px;">Principles</h1>
        <div style="text-align: left;">
          <p>
            All hiring is values-driven. Generalists is an opinionated and differentiated hiring network, designed to foster lattice career development
             and improve hiring success by placing a lens on meta-learning.
          </p>
          <p>
            By self-selection, all candidates and hiring managers on Generalists are of the following mind:
          </p>
          <ul>
            <li>
              Meta-learning is more predictive of success than role tenure.
            </li>
            <li>
              Growth opportunities drive better performance and deeper engagement than repeat roles.
            </li>
            <li>
              Cognitively diverse teams are more productive and innovative.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div>
          <img style="margin-top: 15px; width: 550px; max-width: 100%; height: auto;" src="../../src/assets/hiringcomparison.svg" alt="Chart comparison between conventional hiring and behavioral hiring, indicating the best use cases for each" />
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 divider">
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div>
          <h1 class="font-weight-bold" style="margin-top: 70px; margin-bottom: 30px">Spyro Karidis, Founder</h1>
          <div style="text-align: left;">
            <p>
              Hiring is an intractable problem. Perhaps a better approach to it is a spectrum of solutions, each addressing specific 
              use cases. I'm interminably curious to explore the substance and edges of how credentialing (and self-credentialing) could 
              be better accomplished in order to facilitate non-linear career movement and improve hiring success.
            </p>
          </div>
          <div>
           <a href="https://www.linkedin.com/in/spyroskaridis/" target="_blank"><img src="../../src/assets/linkedin.svg" alt="LinkedIn thumbnail" height="50px;" /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 divider">
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div>
          <h1 class="font-weight-bold" style="margin-top: 70px; margin-bottom: 30px;">The Brand</h1>
          <div style="text-align: center; margin-bottom: 20px;">
            <img style="max-height: 120px;" src="../../src/assets/verticalconfig.svg" alt="Generalists' logo in vertical configuration" />
          </div>
          <div style="text-align: left; margin-bottom: 70px;">
            <p>
              Our logo is made up of two elements—a symbol and a wordmark. Our symbol was designed to (1) celebrate the <span style="font-weight: bold;">fluidity of lifelong learning</span>,
              and (2) emphasize the <span style="font-weight: bold;">depth of motivation</span> and <span style="font-weight: bold;">multi-functional skill set</span> characteristic of Generalists. Our wordmark is the boldness
              of their initiative to move laterally in pursuit of new learning.
            </p>
            <p>
              We hope that it inspires originality in how we hire, and greater freedom in how we design our careers.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.divider {
    border-bottom: 3px dotted #EF5556;
    margin-top: 80px;
  }

h5 {
  padding: 10px;
}

h3 {
  color: #EF5556;
  font-weight: bold;
}
</style>

<script>
export default {
  metaInfo: {
    title: "Generalists - Hiring Principles",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Designed for lateral career movement, all hiring managers on Generalists value demonstrated meta-learning more than role tenure."
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "Designed for lateral career movement, all hiring managers on Generalists value demonstrated meta-learning more than role tenure."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "Generalists - Hiring Principles"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://generalists.co/about"
      }
    ]
  }
};
</script>
