<template>
  <div>
    <div class="container">
      <div style="margin-bottom: 40px;">
        <div class="col-md-12">
          <div style="margin-top: 25px; margin-bottom: 50px;">
            <h1 class="font-weight-bold">Hire for<br /><vue-typer :text="['curiosity', 'creativity', 'hunger', 'the #1 predictive trait of success']" :pre-type-delay='1000' /></h1>
          </div>
          <div>
            <p class="text-secondary" style="font-size: 20px;">
              All candidates on Generalists are:
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4 divider">
          <div class="card">
            <img src="../../src/assets/icons/metalearners.png" class="card-img-top" style="height: 100px; width: auto;" alt="proven meta-learners icon">
            <div class="card-body">
              <h5 class="card-title">Proven Meta-Learners</h5>
              <div class="card-text">
                Present with hard signals of learning agility, innate curiosity, and grit.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 divider">
          <div class="card">
            <img src="../../src/assets/icons/tshaped.png" class="card-img-top" style="height: 100px; width: auto;" alt="t-shaped icon">
            <div class="card-body">
              <h5 class="card-title">T-Shaped</h5>
              <div class="card-text">
                Offer depth + breadth of transferable experience and skills.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <img src="../../src/assets/icons/rare.png" class="card-img-top" style="height: 80px; width: auto; margin-bottom: 19px;" alt="icon of a gem">
              <h5 class="card-title">Rare</h5>
              <div class="card-text">
                Have a growth mindset and explicitly seek change.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
            <form @submit="onSubmit" action="https://generalists.us20.list-manage.com/subscribe/post?u=98ad25d55fa7e99ff295d2875&amp;id=8b4bd303f7" method="post"
                  style="margin-top: 45px; margin-bottom: 25px;" target="_blank">
              <div class="form-group">
                <label class="text-secondary">Hire insatiable learners</label>
                <input type="email" v-model="email" name="EMAIL" placeholder="email"
                       required class="form-control col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <input type="checkbox" value="2" name="group[2823][2]" id="mce-group[2823]-2823-1" style="display: none;" checked>
                  <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input type="text" name="b_98ad25d55fa7e99ff295d2875_8b4bd303f7" tabindex="-1" value="">
                  </div>
              </div>
              <div class="form-group">
                <input type="text" v-model="company" name="MMERGE3" id="mce-MMERGE3" placeholder="company"
                       required class="form-control col-lg-6 offset-lg-3 col-md-8 offset-md-2">
              </div>
              <div>
                <button type="submit" class="btn btn-primary btn-lg" role="button">Request early access</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: 50px;">
      <div class="row">
        <HowItWorks></HowItWorks>
      </div>
    </div>
    <div class="container-fluid logo d-flex justify-content-center">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div>
            <h3 class="font-weight-bold" style="padding: 20px;">Optimize for Engagement</h3>
          </div>
            <div>
            <p>
              The best employees are intrinsically motivated, highly adaptable, excel at learning, and seek stretch opportunities to grow.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <UseCases></UseCases>
      </div>
    </div>
  </div>
</template>

<script>
import HowItWorks from "@/components/HowItWorks.vue";
import UseCases from "@/components/HiringUseCases.vue";
import { VueTyper } from "vue-typer";

export default {
  data() {
    return {
      email: "",
      company: ""
    };
  },
  methods: {
    onSubmit() {
      this.email = "";
      this.company = "";
    }
  },
  components: {
    HowItWorks,
    UseCases,
    VueTyper
  },
  metaInfo: {
    title: "Hire for the #1 Predictive Trait of Success",
    meta: [
      {
        vmid: "description",
        property: "description",
        content:
          "Hire proven meta-learners. The best employees are endogenously motivated, highly adaptable, excel at learning, and seek stretch opportunities to grow."
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Hire proven meta-learners. The best employees are endogenously motivated, highly adaptable, excel at learning, and seek stretch opportunities to grow."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "Hire for the #1 Predictive Trait of Success"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://generalists.co/hiring"
      }
    ]
  }
};
</script>

<style scoped>
.logo {
  display: flex;
  align-items: center;
  height: 300px;
  color: #fff;
  background-color: #31314F;
  background-image: url("../../src/assets/logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.col-md-4 {
  padding: 0px;
}

.card {
  border: none;
}

.card-title {
  text-align: center;
}

.card-body {
  text-align: center;
}

@media (min-width: 1024px) {
  .divider {
    border-right: 3px dotted #EF5556;
  }
}
</style>

<style>
.vue-typer .custom.char {
  color: #2c3e50;
}
</style>
