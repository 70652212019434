import { render, staticRenderFns } from "./HowItWorks.vue?vue&type=template&id=77845c5d&scoped=true&"
var script = {}
import style0 from "./HowItWorks.vue?vue&type=style&index=0&id=77845c5d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77845c5d",
  null
  
)

component.options.__file = "HowItWorks.vue"
export default component.exports