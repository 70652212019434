<template>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div id="blogpost" style="text-align: center; margin-bottom: 5rem;">
        <h1 style="font-size: 2.1em;">{{ post.data.title }}</h1>
        <h4>By {{ post.data.author.first_name }} {{ post.data.author.last_name }}</h4>
        <div v-html="post.data.body" style="text-align: left;"></div>
        <router-link
          v-if="post.meta.previous_post"
          :to="/blog/ + post.meta.previous_post.slug"
          class="button"
        >
          {{ post.meta.previous_post.title }}
        </router-link>
        <router-link
          v-if="post.meta.next_post"
          :to="/blog/ + post.meta.next_post.slug"
          class="button"
        >
          {{ post.meta.next_post.title }}
        </router-link>
        <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
          <form @submit="onSubmit" action="https://generalists.us20.list-manage.com/subscribe/post?u=98ad25d55fa7e99ff295d2875&amp;id=8b4bd303f7" method="post"
                style="margin-top: 45px; margin-bottom: 25px;" target='_blank'>
            <div class="form-group">
              <label class="text-secondary">Get Posts Via Email</label>
              <input type="email" v-model="email" name="EMAIL" placeholder=""
                     required class="form-control col-lg-6 offset-lg-3 col-md-8 offset-md-2">
              <input type="checkbox" value="4" name="group[2823][4]" id="mce-group[2823]-2823-2" style="display: none;" checked>
                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                  <input type="text" name="b_98ad25d55fa7e99ff295d2875_8b4bd303f7" tabindex="-1" value="">
                </div>
            </div>
            <div>
              <button type="submit" class="btn btn-primary btn-lg" role="button">Subscribe</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import butter from "@/services/buttercms.js";
export default {
  name: "blogpost",
  data() {
    return {
      post: null,
      email: ""
    };
  },
  methods: {
    getPost() {
      butter.post
        .retrieve(this.$route.params.slug)
        .then(res => {
          this.post = res.data;
        })
        .catch(res => {
          console.log(res);
        });
    },
    onSubmit() {
      this.email = "";
    }
  },
  watch: {
    $route(to, from) {
      this.getPost();
    }
  },
  created() {
    this.getPost();
  },
  metaInfo: {
    title: "The Most Predictive Trait of the Best Employees",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "According to research and leading hiring managers, the drive, knowledge, and skill to learn and productively apply new knowledge and skills beats role experience."
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "According to research and leading hiring managers, the drive, knowledge, and skill to learn and productively apply new knowledge and skills beats role experience."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "The Most Predictive Trait of the Best Employees"
      },
      {
        vmid: "og:type",
        property: "og:type",
        content: "article"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content:
          "https://generalists.co/blog/the-most-predictive-trait-of-the-best-employees"
      }
    ]
  }
};
</script>
