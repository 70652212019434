<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div>
          <h5>Join hundreds of dynamic professionals in actively choosing
              your next challenge.
          </h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <form @submit="onSubmit" action="https://generalists.us20.list-manage.com/subscribe/post?u=98ad25d55fa7e99ff295d2875&amp;id=8b4bd303f7" method="post"
              target="_blank">
          <div class="form-group">
            <input type="email" v-model="email" name="EMAIL" placeholder="email"
                   required class="form-control col-lg-6 offset-lg-3 col-md-8 offset-md-2">
            <input type="checkbox" value="1" name="group[2823][1]" id="mce-group[2823]-2823-0" style="display: none;" checked>
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input type="text" name="b_98ad25d55fa7e99ff295d2875_8b4bd303f7" tabindex="-1" value="">
              </div>
          </div>
          <div class="form-group">
            <input type="text" v-model="project" name="MMERGE6" id="mce-MMERGE6" placeholder="LinkedIn profile, website or project"
                   required class="form-control col-lg-6 offset-lg-3 col-md-8 offset-md-2">
          </div>
          <div>
            <button type="submit" class="btn btn-primary btn-lg" role="button">Get an email when we launch</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      project: ""
    };
  },
  methods: {
    onSubmit() {
      this.email = "";
      this.project = "";
    }
  }
};
</script>

<style scoped>
h5 {
  margin: 20px;
}

@media (max-width: 767.98px) {
  #lastname {
    margin-top: 15px;
  }
  #password {
    margin-top: 15px;
  }
}
</style>
