<template>
    <div id="app">
      <Header class="header"></Header>
      <div :class="{ 'container': !fullwidth, 'v-center': vCenter }" id="site-content">
        <router-view/>
      </div>
      <Footer class="footer"></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer
  },
  computed: {
    fullwidth() {
      return this.$route.meta.fullwidth === true;
    },
    vCenter() {
      return this.$route.meta.vCenter === true;
    }
  },
  metaInfo: {
    title:
      "Generalists - Masters of the #1 Predictive Trait of Successful Hires",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "For incisive hiring managers and world-class explorers, Generalists is the first and only hiring platform for lateral career movement. Never stop learning."
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "For incisive hiring managers and world-class explorers, Generalists is the first and only hiring platform for lateral career movement. Never stop learning."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content:
          "Generalists - Masters of the #1 Predictive Trait of Successful Hires"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://generalists.co"
      },
      {
        vmid: "og:type",
        property: "og:type",
        content: "website"
      }
    ]
  }
};
</script>

<style>
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.v-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-center > div {
  width: 100%;
  margin-top: -54px;
}

#site-content {
  flex: 1;
}

.header {
  margin-bottom: 20px;
}

.footer {
  height: 60px;
  line-height: 60px;
  background-color: #31314F;
  color: #fff;
}

.btn-primary {
  background: #EF5556;
  border-color: #EF5556;
}

.btn-primary:hover {
  background-color: #c54546;
  border-color: #c54546;
}

.btn-primary:focus {
  background-color: #c54546;
  border-color: #c54546;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background: #EF5556;
  border-color: #EF5556;
}

.card-title {
  font-weight: bolder;
}
</style>
