<template>
  <div>
    <h1 style="margin: 25px;">Thanks so much for your interest!</h1>
      <div class="container">
        <div class="row">
          <div class="col">
            <div>
              <p>To help our community prosper, please tell your friends and fellow generalists about us!</p>
            </div>
             <div class="sharethis-inline-share-buttons" data-url='https://generalists.co' data-title='Generalists - Masters of the #1 Predictive Trait of Successful Hires'>
          </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Thank you!",
     meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Generalists is the first and only hiring platform for lateral career movement. Never stop learning."
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Generalists is the first and only hiring platform for lateral career movement. Never stop learning."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "Generalists - Masters of the #1 Predictive Trait of Successful Hires"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://generalists.co/"
      },
      {
        name: "robots",
        content: "noindex,nofollow"
      }
    ]
  }
};
</script>
