<template>
  <div>
    <h1 class="font-weight-bold" style="margin: 25px;">Take Full Control of Your Career</h1>
      <div class="container">
        <div class="row">
          <div class="col">
            <SignUp></SignUp>
          </div>
        </div>
      </div>
      <div class="container-fluid" style="margin-top: 50px;">
        <div class="row">
          <Benefits></Benefits>
        </div>
      </div>
  </div>
</template>

<script>
import SignUp from "@/components/CandidateSignUp.vue";
import Benefits from "@/components/CandidateBenefits.vue";

export default {
  components: {
    SignUp,
    Benefits
  },
  metaInfo: {
    title: "Generalists - Sign Up",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Chart a custom career path without hindrance. Prioritize passion, lifelong learning, and self-actualization. Be empowered."
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Chart a custom career path without hindrance. Prioritize passion, lifelong learning, and self-actualization. Be empowered."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "Generalists - Sign Up"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://generalists.co/signup"
      }
    ]
  }
};
</script>
