<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #fff;">
      <router-link to="/" class="navbar-brand"><img src="../../src/assets/horizontal.svg" alt="Generalists' logo in horizontal configuration" style="height: 45px; margin-right: 7px;" /></router-link>
      <button @click="active = !active" class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="dropdown-menu dropdown-menu-right" :class="{show : active}" aria-labelledby="navbarDropdown">
        <router-link to="/why" class="dropdown-item">Why</router-link>
        <router-link to="/hiring" class="dropdown-item">Hiring managers</router-link>
        <router-link to="/about" class="dropdown-item">About</router-link>
        <router-link to="/blog" class="dropdown-item">Blog</router-link>
        <div class="dropdown-divider"></div>
        <router-link to="/signup" class="dropdown-item">Sign up</router-link>
        <!-- <router-link v-if="auth" to="/dashboard" class="dropdown-item">Dashboard</router-link>
        <router-link v-if="!auth" to="/login" class="dropdown-item">Log In</router-link>
        <a v-if="auth" @click="onLogout" class="dropdown-item">Log Out</a> -->
      </div>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link to="/why" class="nav-link">Why</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/hiring">Hiring managers</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/blog">Blog</router-link>
          </li>
        </ul>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item">
            <router-link to="/signup" class="nav-link"><button class="btn signup" type="button">Sign up</button></router-link>
          </li>
        </ul>
        <!-- <ul class="navbar-nav justify-content-end">
          <li v-if="!auth" class="nav-item">
            <router-link to="/login" class="nav-link">Log In</router-link>
          </li>
          <li v-if="auth" class="nav-item">
            <router-link to="/dashboard" class="nav-link">Dashboard</router-link>
          </li>
          <li v-if="auth" class="nav-item">
            <button @click="onLogout" class="nav-link logout">Log Out</button>
          </li>
        </ul> -->
      </div>
    </nav>
  </div>
</template>

<script>
import { FirebaseAuth } from "@/Firebase";

export default {
  name: "Header",
  data() {
    return {
      active: false
    };
  },
  computed: {
    auth() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    onLogout() {
      FirebaseAuth.signOut();
      this.$store.commit("logoutUser");
      this.$router.push("/");
    }
  },
  watch: {
    $route() {
      this.active = false;
    }
  }
};
</script>

<style scoped>
.logout {
  background-color: transparent;
  border: none;
  font: inherit;
  color: white;
  cursor: pointer;
}

.btn {
  color: #EF5556;
  border-color: #EF5556;
  border-radius: 1.5rem;
  background-color: #fff;
}
</style>
